<template>
	<div>
		<TableList 
            :form_data_seo.sync="form_data_seo" 
            :columns="columns" 
            :scroll="{x: true}" 
            :get_table_list="this.get_table_list" 
            :is_page="true" 
            ref="list" 
            rowKey="id" 
            :slot_table_list="['operation','needEndReportFlag','pushedMonth']"
            :submit_preprocessing="submit_preprocessing"
        >
			<template slot="right_btn">
				<a @click="handpushMonthVisible=true" style='margin-right:12px;'>
					<a-button  class="a_btn">手动推送</a-button>
				</a>
                <a @click="getPushAllData()">
					<a-button class="a_btn">重新推送</a-button>
				</a>
            </template>
            <template slot="needEndReportFlag" slot-scope="data">
				<span v-if="data.text==0">否</span>
				<span v-else-if="data.text==1">是</span>
			</template>

			<template slot="pushedMonth" slot-scope="data">
				<span v-html="getPushMonth(data.record)"></span>
			</template>

            <template slot="operation" slot-scope="data">
				<a @click="getPage(data.record)" v-if="data.record.pushed_month">查看</a>
                <a-divider type="vertical" v-if="data.record.pushed_month && ifMonthData(data.record.startYearMonth)" />
                <a v-if="ifMonthData(data.record.startYearMonth)" @click="getMonthBetween(data.record)">重新推送</a>
			</template>
			
		</TableList>

		<a-modal :visible="pushMonthVisible" title="请选择重推月份" @cancel="pushMonthCancel" @ok="pushMonthOk">
			<a-checkbox-group :value="pushMonthValue">
				<div style="width:100%;display:inline-block;">
					<div style="width:110px;float:left;" v-for="(item,index) in pusgMonthAllList" :key="index">
						<a-checkbox :value="item" @click="addPushMonthValue(item)">{{ item }}</a-checkbox>
					</div>
				</div>
			</a-checkbox-group>
		</a-modal>
		
		<a-modal width="560px" :visible="handpushMonthVisible" title="手动推送数据" @cancel="HandpushMonthCancel" @ok="HandpushMonthOk">
			<div class="pushBox">
				<div class="pushTitle">推送月份：</div>
				<div><a-month-picker format="YYYY-MM" placeholder="选择需要推送的月份" v-model="month" @change="getActiveIdinfo"></a-month-picker></div>
			</div>
			<div class="pushBox">
				<div class="pushTitle">促销活动ID：</div>
				<div>
					<a-select
						v-model:value="activeIds"
						show-search
						placeholder="选择或查询促销活动ID"
						style="width: 400px"
						:options="activeData"
						mode="multiple"
						@change="getActiveIdsNum"
					>
					</a-select>
				</div>
			</div>
		</a-modal>

	</div>
</template>

<script>
	import { Modal } from 'ant-design-vue';
	import Form from "@/components/Form";
	import TableList from "@/components/TableList";
    import {getSalesList,getCostType,pushAll,pushPromotion,manualPush,getPromotionIds} from "@/api/cost";
import moment from 'moment';
	const columns = [
		{
			title: "促销活动ID",
			dataIndex: "promotionId",
			scopedSlots: {
				customRender: "promotionId"
			}
		},
		{
			title: "促销活动标题",
			dataIndex: "promotionTitle",
			scopedSlots: {
				customRender: "promotionTitle"
			}
		},
        {
			title: "部门",
			dataIndex: "departmentName",
			scopedSlots: {
				customRender: "departmentName"
			}
		},
        {
			title: "主类型",
			dataIndex: "typeName",
			scopedSlots: {
				customRender: "typeName"
			}
		},
        {
			title: "子类型",
			dataIndex: "subTypeName",
			scopedSlots: {
				customRender: "subTypeName"
			}
		},
		{
			title: "返利口径",
			dataIndex: "profitType",
			scopedSlots: {
				customRender: "profitType"
			}
		},
        {
			title: "开始月份",
			dataIndex: "startYearMonth",
			scopedSlots: {
				customRender: "startYearMonth"
			}
		},
        {
			title: "结束月份",
			dataIndex: "endYearMonth",
			scopedSlots: {
				customRender: "endYearMonth"
			}
		},
        {
			title: "是否需要结案",
			dataIndex: "needEndReportFlag",
			scopedSlots: {
				customRender: "needEndReportFlag"
			}
		},
        {
			title: "已推送月份",
			dataIndex: "push_month",
			scopedSlots: {
				customRender: "pushedMonth"
			}
		},
        {
            title: "操作",
            scopedSlots: {
                customRender: "operation"
            }
        },
	];

	export default {
		components: {
			Form,
			TableList
		},
		data() {
			return {
				month:'',
				activeIds:[],
				activeData:[],
				activeIdsNum:10,
				idsEmptyStatus:0,
				handpushMonthVisible:false,
				columns,
				page: 1,
				pagination: {
					...this.$config.pagination
				},
				loading: false,
				list: [],
                get_table_list: getSalesList,

                submit_preprocessing: {
                    array_to_string: ['department','type']
                },
				
                form_data_seo: {
                    ...this.$config.form_data_seo,
                    list: [
                        {
                            type: "tree-select",
                            name: "department",
                            title: "部门",
                            options: {},
                            treeData: [],
                            multiple: true
                        },
                        {
                            type: "text",
                            name: "keyword",
                            title: "关键词",
                            placeholder: '促销活动编号/标题筛选',
                            mode: "default",
                            options: {},
                            list: []
                        },
                        {
                            type: "range_date",
                            name: "range_date",
                            title: "日期",
                            options: {},
                            start: {
                                name: 'start_time'
                            },
                            end: {
                                name: 'end_time'
                            },
                        },
                        {
                            type: "tree-select",
                            name: "type",
                            title: "类型",
                            options: {},
                            treeData: [],
                            multiple: true
                        },
                    ],
                },
				form_data: {},
				pushMonthVisible:false,
				pusgMonthAllList:[],
				pushMonthValue:[],
				pushDataId:'',
			};
		},
		async created() {
			this.$method.get_department().then(res => {
                this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department', 'treeData', res)
            })
            getCostType().then(res => {
                this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'type', 'treeData', res.data.list)
            })
			this.getPromotionActiveIds()
		},
		methods: {
			getActiveIdinfo(){
				if(this.idsEmptyStatus < 1){
					this.activeIds = []
				}
				this.getPromotionActiveIds()
			},
			getActiveIdsNum(){
				if(this.activeIds.length > this.activeIdsNum){
					this.$message.error('所选促销活动ID已超上限，当前上限数量为：'+this.activeIdsNum+'个');
					this.activeIds.splice(this.activeIds.length - 1,1)
				}
			},
			getPromotionActiveIds(){
				getPromotionIds({data:{
					month:this.month ? moment(this.month).format('YYYYMM') : ''
				},info:false}).then(res=>{
					this.activeData = res.data.list
					this.activeData.forEach((item,index)=>{
						item.title = item.key
					})
					this.idsEmptyStatus = res.data.idsEmptyStatus ? parseInt(res.data.idsEmptyStatus) : 0
					this.activeIdsNum = res.data.activeIdsNum ? parseInt(res.data.activeIdsNum) : 10
				})
			},
			HandpushMonthCancel(){
				this.month=''
				this.activeIds = []
				this.handpushMonthVisible=false
			},
			HandpushMonthOk(){
				let that = this
				// Modal.confirm({
				// 	title: () => '提示',
				// 	content: () => '确定要手动推送结案数据吗？',
				// 	onOk() {
						manualPush({data:{
							month:that.month ? moment(that.month).format('YYYYMM') : '',
							ids:that.activeIds ? that.activeIds.join(',') : ''
						},info:true}).then(res=>{
							that.month=''
							that.activeIds = []
							that.handpushMonthVisible=false
						})
					// }
				// });
			},
			getPage(item){
                this.$router.push({path:"./closing_push_details",query:{id:item.id,pushed_month:item.pushed_month}})
            },
			getPushMonth(data){
				if(data.push_month.length){
					let htmls = ''
					for(let i = 0; i < data.push_month.length; i++){
						if(data.push_month[i].status == 4){
							htmls += "<span style='color:green;'>"+data.push_month[i].month+"</span>"
						}else if(data.push_month[i].status == 5){
							htmls += "<span style='color:red;'>"+data.push_month[i].month+"</span>"
						}else{
							htmls += "<span style='color:orange;'>"+data.push_month[i].month+"</span>"
						}
						if(data.push_month[i + 1]){
							htmls += '，'
						}
					}
					return htmls
				}
				return ''
			},
			getMonthBetween(data){
				this.pushDataId = data.promotionId
				this.pusgMonthAllList = []
				this.pushMonthValue = []
				let monthAllData = []
				if(data.startYearMonth && data.endYearMonth){
					let monthAll = this.$method.getMonthBetween(data.startYearMonth,data.endYearMonth)
					//获取当前年月
					let now_y = new Date().getFullYear()
					let now_m = new Date().getMonth() + 1
					now_m = now_m < 10 ? '0' + now_m : now_m
					let now_ym = now_y +''+ now_m
					//去掉大于等于当前月的时间
					for(let i = 0; i < monthAll.length; i++){
						if(parseInt(now_ym) > parseInt(monthAll[i])){
							monthAllData.push(monthAll[i])
						}
					}
					this.pusgMonthAllList = monthAllData
					this.pushMonthVisible = true
					if(this.pusgMonthAllList.length == 1){
						this.pushMonthValue = this.pusgMonthAllList
					}
				}else{
					this.$message.error('活动开始时间或结束时间错误');
				}
			},
			addPushMonthValue(month){
				if(this.pushMonthValue.indexOf(month) > -1){
					this.pushMonthValue = this.pushMonthValue.filter((item) => {
						return item != month;
					});
				}else{
					this.pushMonthValue.push(month)
				}
			},
			pushMonthCancel(){
				this.pushDataId = ''
				this.pushMonthVisible = false
			},
			ifMonthData(infoMonth){
				//获取当前年月
				let now_y = new Date().getFullYear()
				let now_m = new Date().getMonth() + 1
				now_m = now_m < 10 ? '0' + now_m : now_m
				let now_ym = now_y +''+ now_m
				if(parseInt(now_ym) > parseInt(infoMonth)){
					return true
				}
				return false 
			},
			//重推已选月份数据
			pushMonthOk(){
				if(!this.pushMonthValue.length){
					this.$message.error('请选择推送月份');
				}else{
					let id = this.pushDataId
					let month = this.pushMonthValue.join(',')
					pushPromotion({
						data:{
							promotionId:id,
							month:month
						}
					}).then(res => {
						this.pushMonthCancel()
						this.$message.success(res.info);
					})
				}
			},
			//重推所有数据
			getPushAllData(){
				let that = this
				Modal.confirm({
					title: () => '提示',
					content: () => '确定要重新推送结案数据吗？',
					onOk() {
						pushAll().then(res => {
							that.$message.success(res.info);
						})
					}
				});
			},
		}
	};
</script>

<style lang="less">
	@import url("../../assets/less/app.less");

	.pushBox{
		display:flex;
		align-items: center;

		.pushTitle{
			width:90px;
			margin-right:10px;
		}
	}

	.pushBox:last-child{
		margin-top:20px;
	}
</style>
